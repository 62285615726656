export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDialog: false,
    };
  },
  watch: {
    value: {
      handler(val) {
        this.showDialog = val;
      },
      immediate: true,
    },
    showDialog: {
      handler(val) {
        this.$emit('input', val);
      },
      immediate: true,
    },
  },
  methods: {
    // TODO 点击取消
    cancel() {
      this.showDialog = false;
      this.$emit('cancel');
    },
    // TODO 点击确定
    confirm() {
      this.showDialog = false;
      this.$emit('confirm');
    },
  },
};
